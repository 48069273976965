// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-art-foodlovers-js": () => import("./../../../src/pages/art/foodlovers.js" /* webpackChunkName: "component---src-pages-art-foodlovers-js" */),
  "component---src-pages-art-heretohelp-js": () => import("./../../../src/pages/art/heretohelp.js" /* webpackChunkName: "component---src-pages-art-heretohelp-js" */),
  "component---src-pages-art-me-more-y-js": () => import("./../../../src/pages/art/me-more-y.js" /* webpackChunkName: "component---src-pages-art-me-more-y-js" */),
  "component---src-pages-art-pars-colli-js": () => import("./../../../src/pages/art/pars-colli.js" /* webpackChunkName: "component---src-pages-art-pars-colli-js" */),
  "component---src-pages-art-unsung-sheroes-js": () => import("./../../../src/pages/art/unsung-sheroes.js" /* webpackChunkName: "component---src-pages-art-unsung-sheroes-js" */),
  "component---src-pages-art-vim-js": () => import("./../../../src/pages/art/vim.js" /* webpackChunkName: "component---src-pages-art-vim-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-experiments-flowers-2-js": () => import("./../../../src/pages/experiments/flowers2.js" /* webpackChunkName: "component---src-pages-experiments-flowers-2-js" */),
  "component---src-pages-experiments-flowers-3-js": () => import("./../../../src/pages/experiments/flowers3.js" /* webpackChunkName: "component---src-pages-experiments-flowers-3-js" */),
  "component---src-pages-experiments-flowers-4-js": () => import("./../../../src/pages/experiments/flowers4.js" /* webpackChunkName: "component---src-pages-experiments-flowers-4-js" */),
  "component---src-pages-experiments-flowers-js": () => import("./../../../src/pages/experiments/flowers.js" /* webpackChunkName: "component---src-pages-experiments-flowers-js" */),
  "component---src-pages-experiments-js": () => import("./../../../src/pages/experiments.js" /* webpackChunkName: "component---src-pages-experiments-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manifesto-js": () => import("./../../../src/pages/manifesto.js" /* webpackChunkName: "component---src-pages-manifesto-js" */)
}

